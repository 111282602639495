export const API_URL_LOCAL = "http://localhost:1337/api";
export const API_URL_PROD = "/api";
export const API_URL =
  process.env.NODE_ENV === "production" ? API_URL_PROD : API_URL_LOCAL;

export const GOOGLE_SCRIPT_BUILD_ID =
  "AKfycby7JgizNzep6lwXCbshzbMR7PxaeDx6BdXflbxMM-svIfxyOxatZKS1PgfrUeiO4mvN"; // 4 - insert empty pages when needed
// "AKfycbyS83JzZQOU87R3WMPYi9Z0FTq3SDmpD9oEW_NOFJ19fE-D9kvZCgfyrciVGWOSpnap"; // 3 - added drive and docs services
// "AKfycbyHZJjtadMWj_0j7t-kdRGXPlX1y6L0wbjrRfFLqLVYZ6z3m9q9727fjmrIZM9wd_p_"; // 2 - added getGenericTemplates endpoint
// "AKfycbyhYoU4f55UveLbbOtlOr2adH_NwmoYtP_1BxfTqIwxhuPvGMmrU-sOvadK3CeBBhzm"; // 1 on motoparts account
export const GOOGLE_SCRIPT_BUILD_URL = `https://script.google.com/macros/s/${GOOGLE_SCRIPT_BUILD_ID}/exec`;

export const GOOGLE_SCRIPT_DEV_ID =
  "AKfycbw1OA7ibI7c76__3xAjvw1PzQcwLOTIRh4w5lqo3lo";
export const GOOGLE_SCRIPT_DEV_URL = `https://script.google.com/macros/s/${GOOGLE_SCRIPT_DEV_ID}/dev`;

export const GOOGLE_SCRIPT_URL = GOOGLE_SCRIPT_BUILD_URL;
