import { useMemo, useState } from "react";

import {
  GenericFilesResponse,
  getBrandModelFiles,
  getBrandModels,
  getGenericFiles,
} from "../api/api";
import useSignal from "./useSignal";
import { DriveFile, DriveFolder } from "../typings/models";
import useApiEffect from "./useApiEffect";

const useGenericFiles = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [items, setItems] = useState<GenericFilesResponse>({});
  const signal = useSignal();

  useApiEffect(async () => {
    setIsLoading(true);
    const response = await getGenericFiles({ signal });
    if (signal.aborted) return;
    setIsLoading(false);
    if (response.status !== "success") {
      setItems({});
      return;
    }
    setItems(response.data);
  }, []);

  return { items, isLoading };
};

export default useGenericFiles;
