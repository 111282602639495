import {
  ContractFormModel,
  DriveFile,
  DriveFolder,
  GeneratedDocument,
  ServiceSchedule,
} from "../typings/models";
import { ApiHttpService } from "../services/apiHttpService";
import { ApiResponse } from "../typings/interfaces";
import { DocumentTypeEnum } from "../typings/enums";
import { GOOGLE_SCRIPT_URL } from "../constants/common";

const api = new ApiHttpService();

export const generateDocuments = async (
  form: ContractFormModel,
  documents: UUID[] = [],
  init?: RequestInit,
): Promise<ApiResponse<GeneratedDocument>> => {
  return await api.post<GeneratedDocument>(
    `${GOOGLE_SCRIPT_URL}?action=generate`,
    {
      action: "generate",
      form,
      documents,
    },
    init,
  );
};

export const generateServiceScheduleTemplate = async (
  form: ServiceSchedule,
  file: UUID = "",
  folder: UUID = "",
  init?: RequestInit,
): Promise<ApiResponse<GeneratedDocument>> => {
  return await api.post<GeneratedDocument>(
    `${GOOGLE_SCRIPT_URL}?action=generateServiceScheduleTemplate`,
    {
      action: "generateServiceScheduleTemplate",
      form,
      file,
      folder,
    },
    init,
  );
};

export const getBrands = async (
  init?: RequestInit,
): Promise<ApiResponse<DriveFolder[]>> => {
  return await api.get<DriveFolder[]>(
    `${GOOGLE_SCRIPT_URL}`,
    {
      action: "getBrands",
    },
    init,
  );
};

export const getBrandModels = async (
  brandId: string,
  init?: RequestInit,
): Promise<ApiResponse<DriveFolder[]>> => {
  if (!brandId) {
    return {
      status: "success",
      data: [],
    };
  }
  return await api.get<DriveFolder[]>(
    `${GOOGLE_SCRIPT_URL}`,
    {
      action: "getBrandModels",
      brandId,
    },
    init,
  );
};

export const getBrandModelFiles = async (
  brandId: string,
  modelId: string,
  init?: RequestInit,
): Promise<ApiResponse<DriveFile[]>> => {
  if (!brandId) {
    return {
      status: "success",
      data: [],
    };
  }
  return await api.get<DriveFile[]>(
    `${GOOGLE_SCRIPT_URL}`,
    {
      action: "getBrandModelFiles",
      brandId,
      modelId,
    },
    init,
  );
};

export interface GenericFilesResponse {
  legalEntity?: DriveFile[];
  individual?: DriveFile[];
  other?: DriveFile[];
}

export const getGenericFiles = async (
  init?: RequestInit,
): Promise<ApiResponse<GenericFilesResponse>> => {
  return await api.get<GenericFilesResponse>(
    `${GOOGLE_SCRIPT_URL}`,
    {
      action: "getGenericFiles",
    },
    init,
  );
};

export const pingGet = async (
  init?: RequestInit,
): Promise<ApiResponse<GeneratedDocument>> => {
  return await api.get<GeneratedDocument>(`${GOOGLE_SCRIPT_URL}`, {}, init);
};

export const pingGetSimpleFetch = async (): Promise<
  ApiResponse<GeneratedDocument>
> => {
  let request;
  try {
    request = await fetch(`${GOOGLE_SCRIPT_URL}`, {
      method: "GET",
      redirect: "follow",
    });
  } catch (error) {
    console.log("error", error);
  }
  let response = await request?.json();
  return response;
};

export const generateDocumentsSimpleFetch = async (
  form: ContractFormModel,
  documents: DocumentTypeEnum[] = [],
): Promise<GeneratedDocument> => {
  let request;
  try {
    request = await fetch(`${GOOGLE_SCRIPT_URL}?action=generate`, {
      method: "POST",
      redirect: "follow",
      body: JSON.stringify({
        action: "generate",
        form,
        documents,
      }),
    });
  } catch (error) {
    console.log("error", error);
    // handleRequestError(error);
    // return;
  }
  let response = await request?.json();
  return response;
};
