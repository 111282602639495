import { DriveFile } from "../typings/models";

export const DRIVE_FILE_SERVICE_SCHEDUE_TEMPLATE: DriveFile = {
  name: "Service Schedule Template",
  id: "1cYETXz_N5KRgB7C2L14GbU4ur7D8nR3eZJ_sDUDf8VY",
};

// export const DRIVE_FILES_INDIVIDUAL: DriveFile[] = [
//   {
//     name: "Act Premire-predare Fizica",
//     id: "1xHcR0N-419OYcPS8octmZAUSixecIAj6PTEjzVfjXmc",
//   },
//   {
//     name: "Contract Motoparts Fizica",
//     id: "1SEl_KICxkqHV6T4VI3ymxWYsJ8LUNKsA6MK6eod9fzw",
//   },
// ];
//
// export const DRIVE_FILES_LEGAL_ENTITY: DriveFile[] = [
//   {
//     name: "Act Premire-predare Juridica",
//     id: "1RAgDSaWQ0taQTaz2DdO91L5I99tAcVOOuaikTzxOxoo",
//   },
//   {
//     name: "Contract Motoparts Juridica",
//     id: "1OTFGYkgH5uwaBFVQOHfgEDzzRdrFCwcTQa2bFAhk2j4",
//   },
// ];
//
// export const DRIVE_FILES_SERVICE_SCHEDULE: DriveFile[] = [
//   {
//     name: "Service Schedule Template",
//     id: "1cYETXz_N5KRgB7C2L14GbU4ur7D8nR3eZJ_sDUDf8VY",
//   },
// ];
// export const OTHER_DRIVE_FILES_GENERIC: DriveFile[] = [
//   {
//     name: "Talon de Garantie ATV",
//     id: "1J2Ee-F-hyb2CF9DiDu4MTBqASIRCFubLv7WAUsM4PIw",
//   },
//   {
//     name: "Conditii si Instructiuni ATV",
//     id: "1qvJQai9DfWA0X3rfn5eIqpu5MmexiAfRZLcXcLIp3B0",
//   },
// ];
